import React, { useState, useEffect } from 'react';
import './ChatRoomShareMedia.scss';
import { connect } from 'react-redux';
import { FiX, FiRefreshCw } from 'react-icons/fi';
import { doToast } from '../../../../../helper/HelperGeneral';
import SharedMediaTab1 from './sharedMedia/SharedMediaTab1';
import SharedMediaTab2 from './sharedMedia/SharedMediaTab2';
import CaseService from "../../../../../services/chat/caseServices";
import { setActiveSharedMedia } from "../../../../../redux/actions/reduxActionActiveSharedMedia";
import { tapCoreMessageManager } from "@taptalk.io/web-sdk"
// import HelperGetLocalStorage from "../../../../../helper/HelperGetLocalStorage";
// import ButtonWithLoadingOrIcon from "../../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import { Scrollbars } from 'react-custom-scrollbars';

var style = {
  scrollStyle: {
    position: "relative",
    backgroundColor: "#ff7d00",
    right: "-5px",
    width: "3px",
    borderRadius: "8px"
  }
};


var ChatRoomShareMedia = (props) => {
  let [sharedMediaData, setSharedMediaData] = useState({
    media: [],
    file: [],
    link: []
  });
  let [sharedMediaDataTemp, setSharedMediaDataTemp] = useState({
    time: false,
    data: {
      media: [],
      file: [],
      link: []
    },
    room: false
  });
  let [isLoading, setIsLoading] = useState(true);
  let [isError, setIsError] = useState(false);
  let [activeSharedMedia, setActiveSharedMedia] = useState(0);

  let resetSharedMediaData = () => {
    setIsError(false);
    setIsLoading(true);
    setSharedMediaData([]);
  }

  useEffect(() => {
    if (props.parentProps.activeRoom !== null) {
      if ((props.parentProps.activeRoom.roomID === sharedMediaDataTemp.room.roomID) && sharedMediaDataTemp.time) {
        setSharedMediaData(sharedMediaDataTemp.data);
      }
    }
  }, [sharedMediaDataTemp])

  useEffect(() => {
    if (props.activeSharedMedia) {
      getSharedMedia();
      setActiveSharedMedia(0);
    } else {
      resetSharedMediaData();
    }
  }, [props.activeSharedMedia])

  var getSharedMedia = () => {
    setIsLoading(true);

    tapCoreMessageManager.fetchSharedContentMessages(props.parentProps.activeRoom.roomID, 0, 0, "DESC", {
      onSuccess: (dataMedia, dataFile, dataLink) => {
        setSharedMediaDataTemp({
          time: new Date().valueOf(),
          data: {
            media: dataMedia,
            file: dataFile,
            link: dataLink
          },
          room: props.activeRoom
        });

        setIsLoading(false);
      },
      onError: (err) => {
        console.log(err);
      }
    });
  }

  return (
    <div className={`case-shared-media-wrapper ${props.activeSharedMedia ? 'active-case-shared-media' : ''}`}>
      <div className={`case-shared-media-main-wrapper ${props.activeSharedMedia ? "active-case-shared-media-main-wrapper" : ""}`}>
        <div className="case-shared-media-close" onClick={() => props.setActiveSharedMedia(false)}>
          <FiX />
        </div>

        <p className={`title-case-shared-media`}>
          <b>Shared Media</b>
        </p>

        <div className="case-shared-media-list-wrapper">
          <Scrollbars
            autoHideTimeout={500}
            renderThumbVertical={props => <div {...props} style={style.scrollStyle} />}
          >
            {isLoading ?
              <div className="loading-case-action">
                <div className="lds-ring">
                  <div /><div /><div /><div />
                </div>
                <b>Loading Data</b>
              </div>
              :
              (isError ?
                <div className="on-error">
                  <p onClick={getSharedMedia}>
                    <FiRefreshCw />
                    <b>Retry</b>
                  </p>
                </div>
                :
                <div className="shared-media-tab-wrapper">
                  <div className="shared-media-tab">
                    <div
                      className={`shared-media-tab-option ${activeSharedMedia === 0 ? "active-shared-media-tab-option" : ""}`}
                      onClick={() => setActiveSharedMedia(0)}
                    >
                      <b>MEDIA</b>
                    </div>

                    <div
                      className={`shared-media-tab-option ${activeSharedMedia === 1 ? "active-shared-media-tab-option" : ""}`}
                      onClick={() => setActiveSharedMedia(1)}
                    >
                      <b>DOCUMENTS</b>
                    </div>
                  </div>

                  {activeSharedMedia === 0 ?
                    <SharedMediaTab1
                      {...props}
                      sharedMediaData={sharedMediaData.media}
                    />
                    :
                    <SharedMediaTab2
                      {...props}
                      sharedMediaData={sharedMediaData.file}
                    />
                  }
                </div>
              )
            }
          </Scrollbars>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  activeRoom: state.activeRoom,
  activeSharedMedia: state.activeSharedMedia,
  caseDetailData: state.caseDetailData
});

const mapDispatchToProps = {
  setActiveSharedMedia
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatRoomShareMedia);
